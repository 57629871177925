import React, { FC, lazy, Suspense } from "react";
import { Helmet } from "react-helmet-async";
import { BaseProp } from "entities/BaseProp";
import Divider from "components/Divider";
import HeadingText2 from "components/HeadingText/HeadingText2";

// Lazy load components
const AboutUsHeader = lazy(() => import("./AboutUsHeader"));
const WhereToFindUs = lazy(() => import("./WhereToFindUs"));
const HowToContactUs = lazy(() => import("./HowToContactUs"));
const OurPolicyForms = lazy(() => import("./OurPolicyForms"));

export interface AboutUsProps extends BaseProp {}

const AboutUs: FC<AboutUsProps> = ({ className = "" }) => {
  return (
    <div className={`nc-AboutUs ${className}`} data-nc-id="AboutUs">
      <Helmet>
        <title>About CSC LED | Leaders in Sustainable LED Lighting Solutions</title>
        <meta name="description" content="Learn about CSC LED, a trusted provider of innovative and energy-efficient LED lighting products. Discover our mission, values, and commitment to delivering high-quality, sustainable lighting solutions." />
        <meta name="keywords" content="About CSC LED, LED lighting company, Sustainable lighting solutions, Energy-efficient lighting provider, CSC LED mission and values, High-quality LED products, LED lighting expertise, Lighting innovation" />
      </Helmet>

      <div className="container text-[15px] text-justify pt-12 pb-16 lg:pb-28 font-poppins">
        <HeadingText2 className="">About Us</HeadingText2>
        <Suspense fallback={<div>Loading...</div>}>
          <AboutUsHeader />
          <Divider className="my-5" />
          <WhereToFindUs />
          <Divider className="my-5" />
          <HowToContactUs />
          <Divider className="my-5" />
          <OurPolicyForms />
        </Suspense>
      </div>
    </div>
  );
};

export default AboutUs;
